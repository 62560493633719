import React from 'react'
import { Paper } from '@mui/material'

import { Container } from '@mui/material'
import { Typography } from '@mui/material'
import { Grid } from '@mui/material'
import { Box } from '@mui/material'

import { StaticImage } from 'gatsby-plugin-image'

import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import PrintIcon from '@mui/icons-material/Print'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import { styled } from '@mui/material/styles';

const PREFIX = 'Footer';
const classes = {
  root: `${PREFIX}-root`,
  copySection: `${PREFIX}-copySection`,
  container: `${PREFIX}-container`,
  footerLink: `${PREFIX}-footerLink`,
  infoSection: `${PREFIX}-infoSection`,
}

const Root = styled('footer')(({ theme }) => ({
  [`&.${classes.root}`]: {
      borderTop: "8px solid #253f72",
  },
  [`& .${classes.copySection}`]: {
    background: theme.palette.primary.main,
    color: "white",
    boxShadow: "0 0 30px 0 rgb(0 0 0 / 3%)",
    borderRadius: "0",
  },
  [`& .${classes.container}`]: {
    height: "100%",
  },
  [`& .${classes.infoSection}`]: {
    background: "#182346",
    borderRadius: "0",
    color: "white"
  },
  [`& .${classes.footerLink}`]: {
    color: "white",
    textDecoration: "none",
    borderBottom: "1px dotted white",
    "&:hover" : {
        borderColor: theme.palette.secondary.main,
    },
  },  
}))

export default function Footer() {
    return (
        <Root className={classes.root}>
            <Paper className={classes.infoSection}>
                <Box py={3} px={5}>
                    <Container className={classes.footerContainer} sx={{maxWidth: 'md'}}>
                        <Grid container spacing={{ 'sm':6, 'md': 3}} alignItems='center'>
                            <Grid item xs={12} sm={4} md={6} container justifyContent="center">
                                <Box marginY={3}> <StaticImage placeholder="none" loading="eager"  src="../images/logo_white.webp" alt="logo its"/> </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} container spacing={2} justifyContent="center">
                               
                                <Grid item xs={12} container justifyContent={{ sm: "left", xs: "center", md: "left" }} marginTop={{xs: 2, sm: 0}}>
                                    <Typography style={{ fontWeight: 600, fontSize: "0.90rem"}} variant="overline" color="white">i nostri Recapiti</Typography>
                                </Grid>
                                
                                <Grid item container justifyContent="center" spacing={1}>
                                    <Grid item xs={12} container alignItems="center" justifyContent="left">
                                        <Grid item> <LocationOnIcon style={{marginRight: '10px'}} /> </Grid> 
                                        <Grid item> <Typography  variant="body1"> Via delle Buche, 2 <br/> 58019 Monte Argentario (GR)</Typography> </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="left">
                                        <Grid item> <PhoneIcon style={{marginRight: '10px'}} /> </Grid>
                                        <Grid item> <Typography  variant="body1">  <b>telefono</b>: +39 0564 643054</Typography> </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="left">
                                        <Grid item> <EmailIcon style={{marginRight: '10px'}} /> </Grid>
                                        <Grid item> <Typography  variant="body1"> <b>email</b>: <a className={classes.footerLink} href="mailto:info@its-tlc.it">info@its-tlc.it</a>, <a className={classes.footerLink} href="mailto:its-tlc@pec.it">its-tlc@pec.it</a></Typography> </Grid>
                                    </Grid>
                                    <Grid item xs={12} container style={{ marginBottom: '10px' }} alignItems="center" justifyContent="left">
                                        <Grid item> <PrintIcon style={{marginRight: '10px'}} /> </Grid>
                                        <Grid item> <Typography  variant="body1">  <b>fax</b>: +39 0564 743115</Typography> </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Paper>
            <Paper className={classes.copySection}>
                <Container className={classes.footerContainer} maxWidth='md'>
                    <Grid container justifyContent="center" sx={{padding: 1} } spacing={1.5}>
                        <Grid item xs={12} justifyContent="center" container> <Typography style={{ fontWeight: 600, lineHeight: 0 }} variant="overline"> © 2021 ITS Telecomunicazioni s.r.l.s. </Typography> </Grid>
                        <Grid item xs={12} container justifyContent="center"> <Typography variant="caption"> Partita IVA: 016832&zwj;40533</Typography> </Grid>
                    </Grid>
                </Container>
            </Paper>
        </Root>
    )
}

