import * as React from 'react';

import { Collapse, List, ListItem, Button } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function MultiMenuDrawerItem({name, children}) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
      <>
        <ListItem> 
            <Button fullWidth style={{fontWeight: "bolder", color: "rgba(0, 0, 0, 0.87)"}} onClick={handleClick} endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}>{name}</Button>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {children.map( (item) => (<ListItem> <Button fullWidth href={item.href} style={{color: "rgba(0, 0, 0, 0.87)"}}>{item.name}</Button> </ListItem>) )}
            </List>
        </Collapse>
      </>
  );
}