import { createTheme } from "@mui/material"

const lightTheme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#25376e",
      },
      secondary: {
        main: "#e91e63",
      },
      background: {
       default: "#f7f7f8",
      },
    },

  });

export default lightTheme