import React from 'react';
import { useState } from 'react'


import { AppBar } from '@mui/material';
import { IconButton } from '@mui/material';

import { Box } from '@mui/system';
import { Toolbar } from '@mui/material';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import { Button } from '@mui/material'

import { SwipeableDrawer } from '@mui/material';
import { List, ListItem } from '@mui/material';

import { Divider } from '@mui/material';

import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import FacebookIcon from '@mui/icons-material/Facebook'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

import MultiMenuButton from './MultiMenuButton';
import MultiMenuDrawerItem from './MultiMenuDrawerItem';

import navLinks from "../contents/navigation.json"

import { styled } from '@mui/material/styles';


const PREFIX = 'Topbar';
const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  container: `${PREFIX}-container`,
  content: `${PREFIX}-content`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
  },
  [`& .${classes.menuButton}`]: {
    fontWeight: "bolder",
    color: "rgba(0, 0, 0, 0.87)"
  },
  [`& .${classes.container}`]: {
    height: "100%",
  },
  [`& .${classes.content}`]: {
    height: "100%",
    color: "#25376e",
    zIndex: 3,
    position: "relative"
  },
}))

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);


export default function TopBar() {
  const [openMenu, setOpenMenu] = useState(false)

  function toggleMenu() {
    setOpenMenu(!openMenu)
  }

  return (
    <Root className={classes.root}>
      <Box sx={{ flexGrow: 1 }} >
        <AppBar position="static" style={{ background: 'white'}} elevation={10}>
            <Toolbar sx={{padding:2}}>
              <Container className={classes.container} maxWidth='md' pd={2}>
                <Grid className={classes.content} container alignItems='center'>
                  <Grid item style={{marginRight: "auto"}} xs={6}>
                    <Link href="/"> <StaticImage placeholder="none" loading="eager" src="../images/logo.png" alt="logo"/> </Link>
                  </Grid>
                  <Grid item xs={'auto'} >
                    <Grid container alignItems='center' spacing={1} sx={{ display: { sm: 'none', xs: 'none', md: 'flex'} }}>
                        {navLinks.map((menuItem) => {
                          if (menuItem.children) 
                            return <Grid item> <MultiMenuButton name={menuItem.name} children={menuItem.children}/> </Grid>
                          return <Grid item> <Button className={classes.menuButton} href={menuItem.href}>{menuItem.name}</Button> </Grid>
                          }  )} 
                        <Grid item> <IconButton color="primary" href="https://www.facebook.com/its.telecomunicazioni">  <FacebookIcon /> </IconButton> </Grid>
                    </Grid>
                    <Container sx={{ display: { sm: 'flex', xs: 'flex', md: 'none'} }}>
                      <IconButton onClick={toggleMenu}> <MenuIcon /> </IconButton>
                      <SwipeableDrawer  anchor="right" open={openMenu} onOpen={toggleMenu} onClose={toggleMenu}>
                        <Box p={1} alignSelf="center"> <Button style={{fontWeight: "bolder", color: "rgba(0, 0, 0, 0.87)"}} onClick={toggleMenu}> <CloseIcon /> </Button> </Box>
                        <Divider />
                        <List textAlign style={{minWidth: "33vw"}}>
                          { navLinks.map((menuItem) => {
                            if (menuItem.children) 
                              return <MultiMenuDrawerItem name={menuItem.name} children={menuItem.children} />
                            return (<ListItem> <Button fullWidth className={classes.menuButton} style={{fontWeight: "bolder", color: "rgba(0, 0, 0, 0.87)"}} href={menuItem.href}>{menuItem.name}</Button></ListItem>)
                          }
                          ) }
                          <ListItem> <Button fullWidth style={{fontWeight: "bolder", color: "rgba(0, 0, 0, 0.87)"}} className={classes.menuButton} href="https://www.facebook.com/its.telecomunicazioni" startIcon={<FacebookIcon />}> Facebook </Button> </ListItem>
                        </List>
                      </SwipeableDrawer> 
                    </Container>
                  </Grid>
                </Grid>
              </Container>
            </Toolbar>
        </AppBar>
      </Box>
    </Root>
  );
}
