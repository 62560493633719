import * as React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

import { Link } from 'gatsby';

export default function MultiMenuButton({name, children}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
//    this.props.href
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{fontWeight: "bolder",  color: "rgba(0, 0, 0, 0.87)"}}
      >
        { name }
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', }}
        transformOrigin={{vertical: 'top', }}
      >
        {children.map((item) => 
            <MenuItem href={item.href} onClick={handleClose} alignItems="center"> <Link style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)"}} to={item.href}> {item.name}</Link></MenuItem>
        )}
      </Menu>
    </div>
  );
}